import styled from "@emotion/styled";
import { CARDS_MARGIN } from "../sliding-card-grid-base-component.scss";
import { VERTICAL_ALIGN_LABELS } from "constants/align-types";
const ARROWS_ALIGNMENT_TO_STYLE = {
    [VERTICAL_ALIGN_LABELS.TOP]: "flex-start",
    [VERTICAL_ALIGN_LABELS.CENTER]: "center",
    [VERTICAL_ALIGN_LABELS.BOTTOM]: "flex-end"
};
export const StyledCarouselTitleComponent = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: ${({ marginBottom })=>marginBottom || 0};
	width: calc(100% / ${({ visibleCards })=>visibleCards || 1} - ${CARDS_MARGIN});
	${({ arrowsAlignment })=>arrowsAlignment && `align-items: ${ARROWS_ALIGNMENT_TO_STYLE[arrowsAlignment]};`}
	
	.carousel-arrow-wrapper {
		display: flex;
		justify-content: center;
		gap: 12px;

		.carousel-arrow {
			cursor: pointer;

			svg {
				height: 40px;
				width: 40px;
			}
		}
	}
`;
